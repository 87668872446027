<template>
    <div class="card card-secondary">
        <div class="card-header" v-if="showFormTitle">{{ formTitle }}</div>
        <ValidationObserver v-slot="{ invalid }">
            <form @submit="saveForm">
                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-sm-4">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="name">Name *</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter name" v-model="formData.name" required>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="code">Code</label>
                            <input type="text" class="form-control" id="code" placeholder="Enter code" v-model="formData.code">
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="description">Description</label>
                            <input type="text" class="form-control" id="description" placeholder="Enter description" v-model="formData.description">
                        </div>
                        <div class="form-group col-sm-4">
                            <ValidationProvider v-slot="{ errors }" rules="required|positiveFPN">
                                <label for="purchase_rate">Purchase Rate *</label>
                                <input type="text" class="form-control" id="purchase_rate" placeholder="Enter purchase rate" v-model="formData.purchase_rate" required>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-4">
                            <ValidationProvider v-slot="{ errors }" rules="required|positiveFPN">
                                <label for="sell_rate">Sell Rate *</label>
                                <input type="text" class="form-control" id="sell_rate" placeholder="Enter sell rate" v-model="formData.sell_rate" required>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-4">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="brand_id">Brand *</label>
                                <div class="input-group">
                                    <select class="form-control" id="brand_id" v-model="formData.brand_id" required>
                                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="addMeta('brand')"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-4">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="category_id">Category *</label>
                                <div class="input-group">
                                    <select class="form-control" id="category_id" v-model="formData.category_id" required>
                                        <option v-for="category in cats" :key="category.id" :value="category.id">{{ category.name }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="addMeta('category')"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-4">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="group_id">Group *</label>
                                <div class="input-group">
                                    <select class="form-control" id="group_id" v-model="formData.group_id" required>
                                        <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.name }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="addMeta('group')"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-4">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="unit_id">Unit *</label>
                                <div class="input-group">
                                    <select class="form-control" id="unit_id" v-model="formData.unit_id" required>
                                        <option v-for="unit in units" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="addMeta('unit')"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-4">
                            <div class="row">
                                <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                    <label for="upload_image">Image</label>
                                    <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                </div>
                                <div class="col-sm-6" v-if="formData.image">
                                    <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                    <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                </div>
            </form>
        </ValidationObserver>

        <ModalGeneral title="" size="modal-lg" :showModal="metaModal.show" :hideModal="hideMetaModal" v-if="metaModal.show">
            <div slot="modal-body">
                <product-brand :save-handler="metaCreated" v-if="metaModal.type === 'brand'"></product-brand>
                <product-cat :save-handler="metaCreated" v-if="metaModal.type === 'category'"></product-cat>
                <product-group :save-handler="metaCreated" v-if="metaModal.type === 'group'"></product-group>
                <product-unit :save-handler="metaCreated" v-if="metaModal.type === 'unit'"></product-unit>
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
    import Vue from 'vue'

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('realNumber', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return regexFloatingPoint.test(value)? true: 'This field must be a number';
    });
    extend('positiveFPN', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return value >= 0 && regexFloatingPoint.test(value)? true: 'This field must be a positive number';
    });
    import {ProductService} from "@/services/product-service";
    import ModalGeneral from "@/components/app/dialog/ModalGeneral";
    import ProductBrand from "@/components/app/product/ProductBrand";
    import ProductCat from "@/components/app/product/ProductCat";
    import ProductGroup from "@/components/app/product/ProductGroup";
    import ProductUnit from "@/components/app/product/ProductUnit";
    let productService = new ProductService();
    export default {
        name: 'Product',
        data: function(){
            return {
                pageName: 'Product',

                brands: [],
                cats: [],
                groups: [],
                units: [],
                upload_image: null,
                formData: {
                    id: 0,
                    name: null,
                    code: null,
                    description: null,
                    opening_balance: 0,
                    sell_rate: null,
                    purchase_rate: null,
                    image: null,
                    brand_id: null,
                    category_id: null,
                    group_id: null,
                    unit_id: null,
                },
                formTitle: '',

                metaModal:{
                    show: false,
                    type: null,
                },
            }
        },
        props: {
            showFormTitle:{
                type: Boolean,
                default: () => true
            },
            id:{
                type: Number,
                default: () => 0
            },
            saveHandler: {
                type: Function,
                default: function () {
                    this.$router.push({name: 'App.Products'});
                }
            }
        },
        components: {
            ProductUnit,
            ProductGroup,
            ProductCat,
            ProductBrand,
            ModalGeneral,
            ValidationObserver, ValidationProvider
        },
        computed: {

        },
        methods:{
            loadBrands: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await productService.getBrands(false);
                if (response.isSuccess) {
                    this.brands = response.productBrands;
                    if (this.brands.length > 0 && !this.formData.brand_id) {
                        this.formData.brand_id = this.brands[0].id;
                    }
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: 'App.Products'});
                }
                loader.hide();
            },
            loadCats: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});

                let response = await productService.getCats(false);
                if (response.isSuccess) {
                    this.cats = response.productCats;
                    if (this.cats.length > 0 && !this.formData.category_id) {
                        this.formData.category_id = this.cats[0].id;
                    }
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: 'App.Products'});
                }
                loader.hide();
            },
            loadGroups: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await productService.getGroups(false);
                if (response.isSuccess) {
                    this.groups = response.productGroups;
                    if (this.groups.length > 0 && !this.formData.group_id) {
                        this.formData.group_id = this.groups[0].id;
                    }
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: 'App.Products'});
                }
                loader.hide();
            },
            loadUnits: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await productService.getUnits(false);
                if (response.isSuccess) {
                    this.units = response.productUnits;
                    if (this.units.length > 0 && !this.formData.unit_id) {
                        this.formData.unit_id = this.units[0].id;
                    }
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: 'App.Products'});
                }
                loader.hide();
            },
            async loadSavedData() {
                if (this.id !== 0) {
                    this.formData.id = this.id;
                    let loader = this.$loading.show();
                    let response = await productService.getProduct(this.formData.id, 'edit');
                    if (response.isSuccess) {
                        this.formData = response.product;
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.$router.push({name: 'App.Products'});
                    }
                    loader.hide();
                }
            },
            setUploadFile(){
                this.upload_image = this.$refs.upload_image.files[0];
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                productService.formDataProduct = this.formData;
                let response = await productService.saveProduct(this.upload_image);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.$router.push({name: 'App.Products'});
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            unsetUploadFile(){
                if(confirm("Are you sure?")) {
                    this.formData.image = null;
                }
            },
            addMeta(type){
                this.metaModal.type = type;
                this.metaModal.show = true;
            },
            metaCreated: function (data){
                if(data){
                    if(this.metaModal.type === 'brand'){
                        this.brands.push(data);
                        this.formData.brand_id = data.id;
                    }else if(this.metaModal.type === 'category'){
                        this.cats.push(data);
                        this.formData.category_id = data.id;
                    }else if(this.metaModal.type === 'group'){
                        this.groups.push(data);
                        this.formData.group_id = data.id;
                    }else if(this.metaModal.type === 'unit'){
                        this.units.push(data);
                        this.formData.unit_id = data.id;
                    }
                }
                this.hideMetaModal();
            },
            hideMetaModal: function(){
                this.metaModal.type = null;
                this.metaModal.show = false;
                this.metaModal.createdMeta = null;
            }
        },
        async mounted() {
            this.formTitle = (this.id === 0 ? 'Add ' : 'Update ') + this.pageName;
            await this.loadBrands();
            await this.loadCats();
            await this.loadGroups();
            await this.loadUnits();
            await this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
