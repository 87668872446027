<template>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6 offset-3">
                        <div class="card card-secondary">
                            <div class="card-header">{{ formTitle }}</div>
                            <ValidationObserver v-slot="{ invalid }">
                                <form @submit="saveForm">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group col-sm-12">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="name">Name *</label>
                                                    <input type="text" class="form-control" id="name" placeholder="Enter name" v-model="formData.name" required>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-12">
                                                <div class="row">
                                                    <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                                        <label for="upload_image">Image</label>
                                                        <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                                    </div>
                                                    <div class="col-sm-6" v-if="formData.image">
                                                        <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                                        <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" @click="saveHandler()">Cancel</button>
                                        <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>


            </div><!--/. container-fluid -->
        </section>
</template>

<script>
    import Vue from 'vue'

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';
    extend('required', {
        ...required,
        message: 'This field is required'
    });
    import {ProductService} from "@/services/product-service";
    let productService = new ProductService();
    export default {
        name: 'ProductBrand',
        data: function(){
            return {
                pageName: 'Product Brand',
                segments: [
                    { link: true, text: 'Products', routeName: 'App.Products' },
                    { link: true, text: 'Product Brands', routeName: 'App.Product.Brands' },
                    { link: false, text: 'Product Brand' },
                ],

                upload_image: null,
                formData: {
                    id: 0,
                    name: null,
                    image: null,
                },
                formTitle: ''
            }
        },
        props: {
            id:{
                type: Number,
                default: () => 0
            },
            saveHandler: {
                type: Function,
                default: function () {}
            }
        },
        components: {
            ValidationObserver, ValidationProvider
        },
        computed: {

        },
        methods:{
            async loadSavedData() {
                if (this.id !== 0) {
                    this.formData.id = this.id;
                    let loader = this.$loading.show();
                    let response = await productService.getProductBrand(this.formData.id, 'edit');
                    if (response.isSuccess) {
                        this.formData = response.productBrand;
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.saveHandler();
                    }
                    loader.hide();
                }
            },
            setUploadFile(){
                this.upload_image = this.$refs.upload_image.files[0];
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                productService.formDataProductBrand = this.formData;
                let response = await productService.saveProductBrand(this.upload_image);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.saveHandler(response.data);
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            unsetUploadFile(){
                if(confirm("Are you sure?")) {
                    this.formData.image = null;
                }
            }
        },
        mounted() {
            this.formTitle = (this.id === 0? 'Add ': 'Update ') + this.pageName;
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
